import { Button, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

/**
 * Estilos para el botón cuadrado de cierre.
 *
 * Este botón presenta un diseño cuadrado con esquinas ligeramente redondeadas y
 * una apariencia visual atractiva con efectos de hover y active. Se usa principalmente
 * para cerrar elementos emergentes o modales.
 */
const buttonStyles = {
  minWidth: 36, // Anchura mínima del botón.
  minHeight: 36, // Altura mínima del botón.
  borderRadius: "4px", // Bordes ligeramente redondeados para suavizar la apariencia.
  bgcolor: "hsl(356, 91%, 58%)", // Color de fondo en tono rojo brillante.
  color: "white", // Color del ícono.
  display: "flex", // Usa Flexbox para centrar el contenido.
  alignItems: "center", // Centra el ícono verticalmente.
  justifyContent: "center", // Centra el ícono horizontalmente.
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)", // Sombra para efecto de profundidad.
  transition: "transform 0.2s ease-out, box-shadow 0.2s ease-out", // Transiciones suaves.
  "&:hover": {
    bgcolor: "hsl(356, 91%, 80%)", // Color más claro en hover.
    boxShadow: "0px 6px 14px rgba(0, 0, 0, 0.2)", // Mayor sombra en hover.
  },
  "&:active": {
    transform: "scale(0.95)", // Pequeña reducción de tamaño al hacer clic.
    boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.2)", // Sombra más sutil en clic.
  },
};

/**
 * Componente de botón cuadrado con icono de cierre.
 *
 * Este botón permite cerrar elementos como modales o paneles flotantes. Se integra con
 * `Tooltip` para mostrar una etiqueta "Cerrar" cuando el usuario pasa el cursor sobre él.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {Function} props.onClick - Función que se ejecuta al hacer clic en el botón.
 * @returns {JSX.Element} Componente de botón cuadrado de cierre.
 *
 * @example
 * <CloseSquaredBtn onClick={() => console.log("Cerrado")} />
 */
const CloseSquaredBtn = ({ onClick }) => {
  return (
    <Tooltip title="Cerrar" arrow>
      <Button onClick={onClick} sx={buttonStyles}>
        <CloseIcon fontSize="small" />
      </Button>
    </Tooltip>
  );
};

export default CloseSquaredBtn;
