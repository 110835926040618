import { Button } from "@mui/material";

/**
 * Estilos para el botón con icono.
 *
 * Este botón tiene un diseño moderno con un color de fondo llamativo, esquinas redondeadas
 * y efectos visuales al interactuar con él. Puede incluir un icono opcional junto al texto.
 */
const buttonStyles = {
  bgcolor: "hsl(356, 91%, 58%)", // Color de fondo principal (rojo vibrante).
  color: "white", // Color del texto e ícono.
  fontSize: "1.2rem", // Tamaño del texto.
  fontWeight: "bold", // Texto en negrita.
  textTransform: "none", // Mantiene el texto en su formato original.
  px: 5, // Padding horizontal.
  py: 2, // Padding vertical.
  minWidth: "80%", // Anchura mínima del botón.
  borderRadius: "999px", // Bordes completamente redondeados (forma de píldora).
  boxShadow: "0px 3px 8px rgba(0,0,0,0.15)", // Sombra inicial para resaltar el botón.
  transition: "transform 0.15s ease-out, box-shadow 0.15s ease-out", // Suaviza las transiciones.
  display: "flex", // Usa Flexbox para estructurar el contenido.
  alignItems: "center", // Alinea el texto y el icono verticalmente.
  justifyContent: "center", // Centra el contenido horizontalmente.
  gap: 1.5, // Espacio entre el icono y el texto.

  "&:hover": {
    bgcolor: "hsl(356, 91%, 48%)", // Color de fondo más oscuro en hover.
    transform: "scale(1.03)", // Efecto de crecimiento al pasar el cursor.
    boxShadow: "0px 4px 10px rgba(0,0,0,0.2)", // Aumenta la sombra.
  },

  "&:active": {
    transform: "scale(0.98)", // Ligeramente más pequeño al hacer clic.
    boxShadow: "0px 2px 6px rgba(0,0,0,0.2)", // Reduce la sombra.
  },
};

/**
 * Componente de botón con icono (IconBtn).
 *
 * Este botón es altamente reutilizable y puede incluir un icono opcional junto al texto.
 * Se utiliza en formularios, acciones principales y cualquier interacción relevante.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {Function} props.onClick - Función que se ejecuta al hacer clic en el botón.
 * @param {string} props.label - Texto que se mostrará dentro del botón.
 * @param {React.ElementType} [props.icon] - Componente de icono opcional.
 * @param {number} [props.iconSize=32] - Tamaño del icono (por defecto 32px).
 * @returns {JSX.Element} Botón con icono y texto.
 *
 * @example
 * // Ejemplo con icono
 * import { FiShoppingCart } from "react-icons/fi";
 *
 * <IconBtn onClick={() => console.log("Añadir al carrito")} label="Comprar" icon={FiShoppingCart} />
 *
 * @example
 * // Ejemplo sin icono
 * <IconBtn onClick={() => console.log("Enviar formulario")} label="Enviar" />
 */
const IconBtn = ({ onClick, label, icon: Icon, iconSize = 32 }) => {
  return (
    <Button variant="contained" sx={buttonStyles} onClick={onClick}>
      {Icon && <Icon width={iconSize} height={iconSize} fill="currentColor" />}
      <span>{label}</span>
    </Button>
  );
};

export default IconBtn;
