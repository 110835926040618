import WhitePecsaLogo from "../Icons/WhitePecsaLogo";
import { Countdown } from "../Countdown";

const textItems = [
  "Últimos días de precios 2024",
  <WhitePecsaLogo />,
  // <Countdown />,
].map((text, index) => ({
  id: index,
  text,
}));

export const extendedTextItems = [
  ...textItems,
  ...textItems,
  ...textItems,
  ...textItems,
  ...textItems,
  ...textItems,
  ...textItems,
  ...textItems,
  ...textItems,
];
