import * as React from "react";
import { useRef } from "react";
import { Box, height } from "@mui/system";
import Container from '@mui/material/Container';

//import Carousel from "react-multi-carousel";
//import './styles.css';
import { Button, Grid, Stack, Fab, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { motion } from "framer-motion";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ServicioDisponible } from "./ServicioDisponible";

import aguasdesaltillo from '../../assets/serviciosdisponibles/aguasdesaltillo.svg'
import cfe from '../../assets/serviciosdisponibles/cfe.svg'
import izzi from '../../assets/serviciosdisponibles/izzi.svg'
import naturgy from '../../assets/serviciosdisponibles/naturgy.svg'
import totalplay from '../../assets/serviciosdisponibles/totalplay.svg'
import { useEffect } from "react";
import { useState } from "react";

const garantias = [
  { name: 'Luz', desc: 'Disponibilidad de corriente 220 0 110 V', img: cfe }, 

  { name: 'Agua', desc: 'Instalación preparada para conectar', img: aguasdesaltillo },
  { name: 'Internet y Telefonia', desc: 'Servicios subterráneos de Internet y Telefonía', img: izzi },
  { name: 'Gas', desc: 'Preparación para Gas LP y/o Gas Natural', img: naturgy },
  { name: 'Internet y Telefonia', desc: 'Servicios subterráneos de Internet y Telefonía', img: totalplay },
]

const ServiciosDisponibles = ({ fraccionamiento }) => {
    const constraintsRef = useRef(null);
    const containerRef = useRef(null);

    const [serviciosDisponibles, setServiciosDisponibles] = useState([]);
    //const Contenedor = containerRef.current.clientWidth == null || containerRef.current.clientWidth == undefined ? 0 : containerRef.current.clientWidth;
    // const DivVisible = constraintsRef.current.clientWidth;
    // const Diferencia = Contenedor - DivVisible;

    const [Xside, setXside] = React.useState(0);
    const atras = (Xside) => {
      setXside(Xside+395.96);
      console.log(constraintsRef.current.clientWidth);
      console.log(containerRef.current.clientWidth);
    };
    const adelante = (Xside) => {
      setXside(Xside-395.96);
      console.log(constraintsRef.current.clientWidth);
    console.log(containerRef.current.clientWidth);
    };
    useEffect(()=>{
      if(fraccionamiento.nombre.includes('Altavista')){
        setServiciosDisponibles([
          { name: 'Luz', desc: 'Disponibilidad de corriente 220 0 110 V', img: cfe }, 
          { name: 'Agua', desc: 'Instalación preparada para conectar', img: aguasdesaltillo },
          { name: 'Gas', desc: 'Preparación para Gas LP y/o Gas Natural', img: naturgy },
          { name: 'Internet y Telefonia', desc: 'Servicios subterráneos de Internet y Telefonía', img: totalplay },
        ])
      }
      if(fraccionamiento.nombre.includes('Lomas del Morillo') || fraccionamiento.nombre.includes('Encinos')){
        setServiciosDisponibles([
          { name: 'Luz', desc: 'Disponibilidad de corriente 220 0 110 V', img: cfe }, 
          { name: 'Agua', desc: 'Instalación preparada para conectar', img: aguasdesaltillo },
          { name: 'Gas', desc: 'Preparación para Gas LP y/o Gas Natural', img: naturgy },
          { name: 'Internet y Telefonia', desc: 'Servicios subterráneos de Internet y Telefonía', img: totalplay },
        ])
      }
      if(fraccionamiento.nombre.includes('Bosques De Las Lomas')){
        setServiciosDisponibles([
          { name: 'Luz', desc: 'Disponibilidad de corriente 220 0 110 V', img: cfe }, 
          { name: 'Agua', desc: 'Instalación preparada para conectar', img: aguasdesaltillo },
          { name: 'Gas', desc: 'Preparación para Gas LP y/o Gas Natural', img: naturgy },
          { name: 'Internet y Telefonia', desc: 'Servicios subterráneos de Internet y Telefonía', img: izzi },
        ])
      }
      if(fraccionamiento.nombre.includes('El Rocal')){
        setServiciosDisponibles([
          { name: 'Luz', desc: 'Disponibilidad de corriente 220 0 110 V', img: cfe }, 
          { name: 'Agua', desc: 'Instalación preparada para conectar', img: aguasdesaltillo },
          { name: 'Gas', desc: 'Preparación para Gas LP y/o Gas Natural', img: naturgy },
          { name: 'Internet y Telefonia', desc: 'Servicios subterráneos de Internet y Telefonía', img: izzi },
        ])
      }
    }, [fraccionamiento.nombre]);
    return(
        <Container sx={{ pb:2 }}>
            <Box sx={{ textAlign:'left', marginTop:2, color:'#F5323F', }}>
              <h1>Servicios</h1>
            </Box>
            <Box sx={{ textAlign:'left', marginTop:2, color:'#707070', }}>
              <Typography variant="body1" component="div">
                  Todos nuestros fraccionamientos cuentan con los servicios basicos
              </Typography>
            </Box>

            <Box
              component={motion.div}
              ref={constraintsRef}
              sx={{
                //backgroundColor:'white', 
                display: 'flex',
                placeContent: 'start',
                placeItems: 'center',
                overflow: 'hidden',
                position:'relative',
                marginTop: 3,
                marginBottom: 5
              }}
            >
              <Stack
              ref={containerRef}
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                //spacing={2}
                component={motion.div}
                animate={{ x:Xside }}
                drag='x' 
                dragConstraints={constraintsRef}
              >
                {
                  serviciosDisponibles.map((garantia) => (
                    <Box
                      component={motion.div}
                      sx={{ 
                        //backgroundColor:'red',
                         width:{ xs: '145px', sm:'185px', md:'280px' },
                        //width:{ xs: '145px', sm:'185px', md:'222px' },
                        ml:0.5,
                        mr:0.5,
                      }}                  
                    >
                      <ServicioDisponible garantia={garantia}/>  
                    </Box>
                  ))
                }                                               
              </Stack>
              {/* <Box 
                component="Button" 
                sx={{ 
                  pt: 3, pb:3, pl:0, pr:0,
                  backgroundColor:'white', 
                  border:'none',
                  color:'red',
                  zIndex:1, 
                  position:'absolute',
                  left:0,
                }}
                variant="contained"
                onClick={()=> atras(Xside)}
              >
                  <ChevronLeftIcon />
                </Box>
                <Box 
                component="Button" 
                sx={{ 
                  pt: 3, pb:3, pl:0, pr:0,
                  backgroundColor:'white', 
                  border:'none',
                  color:'red',
                  zIndex:1, 
                  position:'absolute',
                  right:0,
                }}
                variant="contained"
                onClick={()=> adelante(Xside)}
              >
                  <ChevronRightIcon />
                </Box> */}
              {/* <Button onClick={()=> setXside(Xside+1125)} sx={{ zIndex:1, position:'absolute' }}>atras</Button>
                <Button onClick={()=> setXside(Xside-1125)} sx={{ zIndex:1, position:'absolute' }}>adelante</Button> */}
            </Box>
            
            <Box sx={{ textAlign:'left', marginTop:2, color:'#707070', }}>
              <Typography variant="subtitle2" component="div">
                  *Sujeto a disponibilidad del fraccionamiento
              </Typography>
            </Box>
                

        </Container>
    );
}

export { ServiciosDisponibles }