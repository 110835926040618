/**
 * Hook personalizado `useAppointment`
 *
 * Aqui se maneja la logica para reedirecionar al calendario de cada fraccionamiento
 */

const useAppointment = () => {
  const handleAppointment = (name) => {
    window.open(`${process.env.REACT_APP_URL}/citas?fraccionamiento=${name}`);
  };

  return { handleAppointment };
};

export default useAppointment;
