import * as React from "react";

const EncinosIcon = ({ width = 32, height = 32, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="currentColor"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#a)">
      <path d="M12.21 0c-13.597-.3-11.096 20.158-.227 24C22.545 20.242 25.535.432 12.21 0Zm.694 2.593c1.717.168 3.013.829 3.945 1.813l-3.945 4.538v-6.35Zm-1.808-.012v11.586L5.332 8.584c.341-3.145 2.104-5.69 5.764-6.015v.012Zm-5.73 8.633 5.73 5.546v3.986c-3.149-1.788-5.275-5.75-5.73-9.532Zm7.538 9.508v-8.968l5.002-5.763c2.081 4.466-.33 12.006-5.002 14.731Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default EncinosIcon;
