import * as React from "react";

const RocalIcon = ({ width = 32, height = 32, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="currentColor"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M15.129 17.958c.104-.753.21-1.506.314-2.24.063-.47.125-.941.272-1.412.523-1.675 1.57-1.901 3.076-1.77.69.057 1.318.283 1.82.772.544.527.67 1.205.335 1.864-.377.734-.92 1.374-1.507 1.995-.648.696-1.339 1.336-2.155 1.882-.063.038-.104.057-.167.095-.44.263-.921.489-1.423.263-.481-.226-.481-.734-.523-1.186v-.244h-.021l-.021-.02ZM9.25 11.219c-.586.038-1.15-.075-1.716-.17-.92-.15-1.339-.752-1.13-1.58.252-.998.837-1.45 2.093-1.6.753-.095 1.506-.151 2.26-.302.607-.113 1.192-.339 1.8-.527.313-.094.648-.094.962-.019.67.15 1.004.584.92 1.224-.041.395-.125.79-.209 1.167-.188.81-.774 1.261-1.611 1.487-.46.132-.942.17-1.423.207-.628.056-1.276.056-1.925.094l-.02.019ZM13.098 16.056c-.816 0-1.528-.169-2.197-.414-.858-.3-1.151-.922-.837-1.694.418-1.016 1.193-1.826 2.155-2.428 1.046-.64 2.072-.376 2.553.715.25.603.46 1.205.481 1.845.063 1.205-.67 1.92-2.009 1.939h-.146v.038ZM9.165 7.36a7.071 7.071 0 0 1-1.548-.15c-1.026-.245-1.381-.904-.858-1.732.753-1.205 1.904-1.977 3.41-2.26.858-.15 1.444.283 1.528 1.055.126.979-.21 1.882-.523 2.786-.063.17-.21.17-.356.188-.544.038-1.088.113-1.653.094v.019ZM19.042 8.979c-.063-.094-.147-.038-.21 0-.962.376-1.945.49-2.991.433-.544-.038-.775-.245-.775-.734 0-.32.084-.621.272-.885.586-.866 1.151-1.732 1.988-2.428.398-.34.712-.32 1.067.056.774.828.942 1.826.921 2.88 0 .188-.23.32-.167.527.062.113 0 .15-.126.17l.021-.02ZM14.92 17.224c-.104.677-.21 1.506-.44 2.277-.209.697-.606.847-1.338.527-.69-.3-1.36-.677-1.925-1.167-.063-.056-.126-.094-.168-.15-.628-.66-.565-1.017.23-1.487.795-.471 1.674-.772 2.595-.942.795-.15 1.046.076 1.025.942h.021ZM14.27 7.021c-1.192 0-1.946-.696-1.946-1.807 0-1.073.69-1.75 1.758-1.75 1.255 0 2.302.884 2.28 1.976-.02 1.073-.69 1.562-2.092 1.6v-.019ZM5.484 13.892c1.15-.113 1.611.527 1.841 1.261.272.885.23 1.75-.104 2.616-.105.302-.272.452-.69.32-1.319-.395-2.156-1.223-2.553-2.39-.252-.697.104-1.205.941-1.6.147-.17.356-.188.565-.207Z" />
    <path
      fill="#000"
      d="M4.75 14.024c-.335.037-.69.113-1.046.075-.44-.038-.733-.32-.628-.697.272-1.016.816-1.938 1.716-2.635.314-.245.648-.263 1.025-.038.732.452 1.297.998 1.632 1.751.105.264.063.414-.23.565-.586.282-1.193.546-1.82.771-.189.17-.44.17-.67.208h.02ZM16.863 12.367c-.649-.056-1.465-.17-2.072-.772-.543-.546-.564-1.355-.062-1.92a.509.509 0 0 1 .502-.17c1.255.227 2.406.679 3.306 1.544.146.151.251.32.293.509a.55.55 0 0 1-.46.659c-.44.075-.858.169-1.486.15h-.021ZM7.783 16.621c0-.47.104-.903.272-1.336.042-.113.083-.226.25-.226.796-.038 1.528.056 2.072.659.649.696.356 1.656-.586 1.976-.544.188-1.109.113-1.674 0-.146-.037-.209-.132-.25-.245-.084-.263-.084-.545-.105-.828h.02ZM5.962 9.675c.042.189-.062.301-.209.396-.502.376-1.088.583-1.737.677-.648.094-1.046-.244-1.067-.847 0-.49.147-.922.419-1.336.397-.621 1.088-.734 1.736-.32.524.339.775.847.837 1.411l.021.02ZM10.15 12.424c-.021.395-.21.94-.565 1.449-.335.49-.754.584-1.34.32-.188-.094-.376-.188-.543-.301-.147-.094-.252-.226-.23-.396.146-.828.48-1.562 1.339-1.995.795-.395 1.318-.113 1.318.923h.02ZM19.04 8.979c.062-.038.125-.094.125-.17 1.004-.357 2.05.038 2.301.885.147.47-.083.941-.585 1.148a2.615 2.615 0 0 1-1.654.076c-.397-.113-.627-.433-.564-.81.062-.395.209-.772.376-1.13ZM19.9 11.802c0-.357.293-.583.774-.583.46 0 .837.263.837.602 0 .396-.502.829-1.004.847-.377 0-.628-.3-.628-.866h.02ZM5.816 8.094c-.314 0-.878-.414-.878-.64 0-.395.418-.753.857-.753.377 0 .628.245.628.584 0 .414-.314.828-.607.828v-.019ZM8.766 18.805c0-.358.25-.603.606-.603.398 0 .921.396.921.697 0 .245-.565.659-.879.64-.293 0-.627-.395-.627-.734h-.021Z"
    />
    <path d="M4.75 14.024c.23-.076.44-.132.67-.207l.083.075c-.188.094-.355.17-.565.207-.042-.057-.104-.075-.167-.075H4.75Z" />
  </svg>
);

export default RocalIcon;
