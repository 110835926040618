import { Box, Typography } from "@mui/material";
import { CloseCircledBtn, CloseSquaredBtn } from "../../Buttons";

/**
 * Componente de contenido para cajones (DrawerContent).
 *
 * Este componente sirve como contenedor para los diferentes contenidos de los drawers (desplegables).
 * Incluye un título opcional y un botón de cierre adaptable según el tamaño de la pantalla.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {string} [props.title] - Título opcional que se muestra en la parte superior.
 * @param {Function} props.onClose - Función que se ejecuta al cerrar el drawer.
 * @param {React.ReactNode} props.children - Contenido que se renderiza dentro del drawer.
 * @param {boolean} props.isMobile - Indica si el drawer se está mostrando en un dispositivo móvil.
 * @returns {JSX.Element} Contenido estructurado del drawer con título y botones de cierre.
 *
 * @example
 * // Ejemplo de uso en un drawer móvil
 * <DrawerContent title="Opciones" onClose={handleClose} isMobile={true}>
 *   <p>Contenido dentro del drawer</p>
 * </DrawerContent>
 */
const DrawerContent = ({ title, onClose, children, isMobile }) => {
  return (
    <Box
      sx={{
        width: "100%",
        maxHeigh: isMobile ? "clamp(60vh, 70vh, 80vh)" : "auto", // Altura adaptable en móviles
        bgcolor: isMobile ? "hsl(210, 17%, 98%)" : "transparent", // Fondo claro en móviles
        borderTopLeftRadius: "12px", // Bordes redondeados superiores
        borderTopRightRadius: "12px",
        fontFamily: "Montserrat",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
      role="presentation"
      onKeyDown={onClose} // Manejo de cierre con teclado
    >
      {/* Renderiza el título si existe */}
      {title && (
        <Box sx={{ p: 2 }}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              fontFamily: "Montserrat",
              textAlign: "center",
              fontSize: isMobile ? "1.75rem" : "1.25rem",
              color: "hsl(0, 0%, 35%)",
            }}
          >
            {title}
          </Typography>
        </Box>
      )}

      {/* Contenido dinámico del drawer */}
      <Box sx={{ p: isMobile ? 2 : 0, flex: 1 }}>{children}</Box>

      {/* Botón de cierre: circular en móviles, cuadrado en escritorio */}
      {isMobile ? (
        <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
          <CloseCircledBtn onClick={onClose} />
        </Box>
      ) : (
        <Box sx={{ position: "absolute", top: "0px", right: "0px" }}>
          <CloseSquaredBtn onClick={onClose} />
        </Box>
      )}
    </Box>
  );
};

export default DrawerContent;
