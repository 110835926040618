import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import axios from "axios";
import { useEffect, useState } from "react";
import { CircularLoadingAnimation } from "../CircularLoadingAnimation";

/**
 * Componente `FraccionamientosDrawerList`
 *
 * Muestra una lista de fraccionamientos disponibles en un drawer, con animaciones suaves y estilos personalizados.
 * Cada fraccionamiento incluye un nombre, una ubicación y un icono opcional.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {Array} props.fraccionamientos - Lista de fraccionamientos a mostrar.
 * @param {Function} props.onSelect - Función que se ejecuta al seleccionar un fraccionamiento.
 * @param {boolean} props.open - Indica si el drawer está abierto para activar las animaciones.
 * @returns {JSX.Element} Lista interactiva de fraccionamientos.
 *
 * @example
 * <FraccionamientosDrawerList
 *   fraccionamientos={listaDeFraccionamientos}
 *   onSelect={handleSeleccion}
 *   open={isDrawerOpen}
 * />
 */
const removerNumerosRomanos = (cadena) => {
  const regex = /\bM{0,4}(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})\b/g;
  return cadena.replace(regex, match => /^[IVXLCDM]+$/.test(match) ? '' : match).trim().replace(/\s+/g, ' ');
}
const FraccionamientosDrawerList = ({ fraccionamientos, onSelect, open }) => {
  const [fraccionamientosCalendly, setFraccionamientosCalendly] = useState([])
  const getEventTypeList = async () => {
    try {
      const { data } = await axios.get(`https://api.calendly.com/event_types?organization=https://api.calendly.com/organizations/440a8b06-a5c6-4271-9fdf-8e65f81f7e40`,{
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzQyMzM4MDY1LCJqdGkiOiIyNzJkMzViYi04MTM4LTRmODctYjc3NS0zMGZjMjQwZjRhYjkiLCJ1c2VyX3V1aWQiOiIzNjY1ZjFhYy1hOTgzLTRmOTEtOWE4OS0wYjY5ZGJlMjg4MTAifQ.Z6p5bW__P4s7Xx0y3QDktQHAglLgeu60WnLwe4AzgEWW0O2DpLrTcZHu7RSIc8-gzlESidt0qKhiBdOEPQDemg'
        }
      });
  
      // console.log('data', data.collection.map(f=>f.name));
      // console.log('fraccionamientos', fraccionamientos);
      // console.log('filter', data.collection.filter(f => f.active))
      const dataActivos = data.collection.filter(f => f.active);
      const dataResult = dataActivos.map(f => {
        const fraccionamiento = fraccionamientos.find(fs => removerNumerosRomanos(f.name).split('residencial').join('').toLowerCase().includes(removerNumerosRomanos(fs.nombre).toLowerCase()));
        return {
            ...f,
            ...fraccionamiento ? fraccionamiento : { nombre: f.name, }
        }
      });
      setFraccionamientosCalendly(dataResult)
    } catch (error) {
      console.log(error)
    }
  }
  
  useEffect(() => {
    getEventTypeList()
  },[]);
  
  return (
    <List disablePadding disableGutters sx={{ display: "grid", gap: 1 }}>
      {
        fraccionamientosCalendly.length === 0 && (
          <Box
            sx={{
              width:'100%',
              textAlign:'center'
            }}
          >
            <CircularProgress sx={{ color:'rgb(195, 9, 22)' }}/>
          </Box>
        )
      }
      {fraccionamientosCalendly.map((fraccionamiento, index) => (
        <ListItem
          key={index}
          disablePadding
          disableGutters
          sx={{
            opacity: 0,
            transform: "translateY(10px)",
            animation: open
              ? `fadeIn 0.4s ease-out ${index * 0.1}s forwards`
              : "none",
            "@keyframes fadeIn": {
              "0%": { opacity: 0, transform: "translateY(10px)" },
              "100%": { opacity: 1, transform: "translateY(0)" },
            },
          }}
        >
          <ListItemButton
            onClick={() => onSelect(fraccionamiento.slug)}
            sx={{
              p: 2,
              borderRadius: "8px",
              border: "1px solid hsl(356, 91%, 85%)",
              display: "flex",
              alignItems: "center",
              gap: 2,
              bgcolor: "hsl(0, 0%, 99%)",
              transition: "background-color 0.3s ease",
              "&:hover": {
                bgcolor: "hsl(356, 91%, 95%)",
              },
            }}
          >
            {/* Icono del fraccionamiento */}
            {
              fraccionamiento.icono ? (fraccionamiento.icono) : (<Box width={32}/>)
            }

            {/* Nombre y ubicación del fraccionamiento */}
            <ListItemText
              primary={
                <Typography
                  sx={{ fontFamily: "Montserrat", fontSize: "1.2rem" }}
                >
                  {fraccionamiento.nombre}
                </Typography>
              }
              secondary={
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: "0.9rem",
                    color: "hsl(356, 91%, 58%)",
                  }}
                >
                  {fraccionamiento.ubicacion ? (
                    `Al ${fraccionamiento.ubicacion} de la ciudad`
                  ) : (
                    '¡Agenda tu cita aquí!'
                  )}
                </Typography>
              }
            />

            {/* Flecha animada a la derecha */}
            <ArrowForwardIosIcon
              sx={{
                fontSize: "1rem",
                color: "hsl(0, 0%, 50%)",
                transition: "transform 0.2s ease",
                "@keyframes moveRight": {
                  "0%": { transform: "translateX(0)" },
                  "50%": { transform: "translateX(6px)" },
                  "100%": { transform: "translateX(0)" },
                },
                ".MuiListItemButton-root:hover &, .MuiListItemButton-root:focus-within &":
                  {
                    animation: "moveRight 1.1s infinite ease-in-out",
                  },
              }}
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default FraccionamientosDrawerList;
