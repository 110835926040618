import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";

import { IconBtn } from "../Buttons";
import { FraccionamientosDrawerList } from "../fraccionamientos";
import { PecsaCalendarIcon } from "../../assets/icons";
import BottomStickyBar from "../BottomStickyBar";
import DrawerContent from "./drawerComponents/DrawerContent";
import useAppointment from "../../hooks/useAppointment";

/**
 * Componente `BottomDrawer`
 *
 * Este componente muestra un botón flotante en la parte inferior de la pantalla para abrir un drawer (cajón) deslizante desde abajo.
 * En el drawer se muestra una lista de fraccionamientos disponibles para agendar una cita.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {Array} props.fraccionamientos - Lista de fraccionamientos disponibles.
 * @param {boolean} props.isMobile - Indica si se está ejecutando en un dispositivo móvil.
 * @returns {JSX.Element} Botón de agendar cita con un drawer emergente.
 *
 * @example
 * <BottomDrawer fraccionamientos={listaDeFraccionamientos} isMobile={true} />
 */
const BottomDrawer = ({ fraccionamientos, isMobile }) => {
  const [open, setOpen] = useState(false);
  const { handleAppointment } = useAppointment();

  /**
   * Función para abrir o cerrar el drawer.
   * Previene el cierre si el evento es de teclado y corresponde a "Tab" o "Shift".
   *
   * @param {boolean} open - Estado del drawer (true para abrir, false para cerrar).
   * @returns {(event: React.KeyboardEvent | React.MouseEvent) => void} Manejador del evento.
   */
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  return (
    <BottomStickyBar>
      {/* Botón flotante para abrir el drawer */}
      <IconBtn
        label="Agendar Cita"
        icon={PecsaCalendarIcon}
        onClick={toggleDrawer(true)}
      />

      {/* Drawer deslizante desde la parte inferior */}
      <Drawer anchor="bottom" open={open} onClose={toggleDrawer(false)}>
        <DrawerContent
          title="Elige tu fraccionamiento"
          onClose={toggleDrawer(false)}
          isMobile={isMobile}
        >
          {/* Lista de fraccionamientos disponibles */}
          <FraccionamientosDrawerList
            fraccionamientos={fraccionamientos}
            onSelect={handleAppointment}
            open={open}
          />
        </DrawerContent>
      </Drawer>
    </BottomStickyBar>
  );
};

export default BottomDrawer;
