import * as React from "react";

const PecsaCalendarIcon = ({ width = 32, height = 32, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="currentColor"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="m19.288 17.26.66.66-2.74 2.74-1.52-1.52.65-.66.87.84 2.08-2.06Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="m17.22 15.17 1.85 1.86-1.38 1.36-.85-.82-1.37 1.34-1.02-.98.85-.85v-1.31h.77v.55l1.15-1.15Z"
      clipRule="evenodd"
    />
    <path d="M19.82 12.42V5.77c0-2.4-1.95-4.35-4.35-4.35H5.06C2.66 1.43.71 3.37.71 5.77v8.68c0 2.4 1.95 4.35 4.35 4.35h6.12c.43 2.94 2.96 5.2 6.02 5.2 3.36 0 6.08-2.73 6.08-6.08 0-2.42-1.42-4.51-3.47-5.49l.01-.01ZM5.06 3.19h10.42c1.42 0 2.58 1.15 2.58 2.57H2.48c0-1.42 1.16-2.57 2.58-2.57ZM2.48 14.45V7.52h15.58v4.37a6.087 6.087 0 0 0-6.87 5.14H5.07c-1.42 0-2.58-1.16-2.58-2.58h-.01Zm14.72 7.79c-2.38 0-4.32-1.94-4.32-4.32 0-2.38 1.94-4.32 4.32-4.32 2.38 0 4.32 1.94 4.32 4.32 0 2.38-1.94 4.32-4.32 4.32ZM6.81 10.13c0 .49-.39.88-.88.88h-.87c-.49 0-.88-.39-.88-.88s.39-.88.88-.88h.87c.49 0 .88.39.88.88Zm2.14 0c0-.49.39-.88.88-.88h.87c.49 0 .88.39.88.88s-.39.88-.88.88h-.87c-.49 0-.88-.39-.88-.88Zm4.77 0c0-.49.39-.88.88-.88h.87c.49 0 .88.39.88.88s-.39.88-.88.88h-.87c-.49 0-.88-.39-.88-.88Zm-6.91 3.46c0 .49-.39.88-.88.88h-.87c-.49 0-.88-.39-.88-.88s.39-.88.88-.88h.87c.49 0 .88.39.88.88Zm3.89.89h-.87c-.49 0-.88-.39-.88-.88s.39-.88.88-.88h.87c.49 0 .88.39.88.88s-.39.88-.88.88Z" />
    <path d="M6.14 0h-.01a.84.84 0 0 0-.84.84v2.62c0 .464.375.84.84.84h.01a.84.84 0 0 0 .84-.84V.84A.84.84 0 0 0 6.14 0ZM10.268 0h-.01a.84.84 0 0 0-.84.84v2.62c0 .464.376.84.84.84h.01a.84.84 0 0 0 .84-.84V.84a.84.84 0 0 0-.84-.84ZM14.4 0h-.01a.84.84 0 0 0-.84.84v2.62c0 .464.377.84.84.84h.01a.84.84 0 0 0 .84-.84V.84A.84.84 0 0 0 14.4 0Z" />
  </svg>
);

export default PecsaCalendarIcon;
