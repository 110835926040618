import * as React from "react";

const BosquesIcon = ({ width = 32, height = 32, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="currentColor"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#a)">
      <path d="M4.135 4.543s1.6 2.272 2.595 5.415l-2.556 3.098-.04-8.513Zm0 9.729v5.437c5.385-.55 3.005-8.948 3.005-8.948l-3.005 3.534v-.024ZM1.813 7.754 3.51 9.683V4.497L1.813 7.778v-.023Zm-.371.757C.544 10.05.252 12.184.252 12.184l3.258 3.785v-5.093L1.442 8.512ZM.115 13.17c-.858 6.516 3.532 6.516 3.532 6.516v-2.455l-3.532-4.06Zm20.41-.137 2.556-3.098c-.995-3.143-2.595-5.415-2.595-5.415l.039 8.513Zm-.04 1.239v5.437c5.386-.55 3.006-8.948 3.006-8.948l-3.005 3.534v-.024Zm-2.34-6.517 1.697 1.928V4.497l-1.697 3.281v-.023Zm-.371.757c-.898 1.538-1.19 3.672-1.19 3.672l3.258 3.785v-5.093l-2.068-2.364Zm-1.327 4.658c-.859 6.516 3.532 6.516 3.532 6.516v-2.455l-3.532-4.06ZM11.686.046s-1.6 2.271-2.595 5.415l2.556 3.097.039-8.512ZM8.68 6.24s-2.38 8.42 3.005 8.948V9.751L8.68 6.218v.023ZM12.33 0v5.185l1.697-1.927L12.33 0Zm2.068 4.015L12.33 6.38v5.093l3.258-3.786s-.292-2.133-1.19-3.67Zm1.327 4.658-3.532 4.061v2.455s4.39 0 3.532-6.516Zm-3.395 6.516h-.722v8.582h.722v-8.582ZM3.51 24h.722v-4.337H3.51V24Zm16.332 0h.722v-4.337h-.722V24Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default BosquesIcon;
