import { Button, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

/**
 * Estilos para el botón circular de cierre.
 *
 * El botón tiene un estilo circular con un color base y efectos de hover y click que
 * mejoran la experiencia del usuario, como el cambio de escala y sombra. El ícono
 * de cierre se centra dentro del botón.
 */
const buttonStyles = {
  minWidth: 56, // Anchura mínima del botón.
  minHeight: 56, // Altura mínima del botón.
  borderRadius: "50%", // Hace el botón circular.
  bgcolor: "hsl(356, 91%, 90%)", // Color de fondo del botón (tono suave de rojo).
  color: "hsl(356, 91%, 40%)", // Color del ícono (tono más oscuro de rojo).
  display: "flex", // Usamos Flexbox para alinear el contenido.
  alignItems: "center", // Centra el ícono verticalmente.
  justifyContent: "center", // Centra el ícono horizontalmente.
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)", // Sombra para el efecto de profundidad.
  transition: "transform 0.2s ease-out, box-shadow 0.2s ease-out", // Transición suave al cambiar de estado.
  "&:hover": {
    bgcolor: "hsl(356, 91%, 80%)", // Color de fondo en hover (tono más oscuro de rojo).
    transform: "scale(1.1)", // Aumenta el tamaño del botón al pasar el ratón.
    boxShadow: "0px 6px 14px rgba(0, 0, 0, 0.2)", // Aumenta la sombra en hover para mayor énfasis.
  },
  "&:active": {
    transform: "scale(0.95)", // Reduce ligeramente el tamaño del botón en clic.
    boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.2)", // Sombra más sutil en clic.
  },
};

/**
 * Componente de botón circular con icono de cierre.
 *
 * Este botón circular está diseñado para ofrecer una experiencia interactiva y visualmente
 * atractiva con un ícono de cierre. Al hacer clic, se dispara una acción pasada a través
 * de la prop `onClick`. El botón también cuenta con un tooltip que aparece al pasar el ratón.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {Function} props.onClick - Función que se ejecuta al hacer clic en el botón.
 * @returns {JSX.Element} Componente de botón circular de cierre.
 *
 * @example
 * <CloseCircledBtn onClick={() => alert("Cerrado")} />
 */
const CloseCircledBtn = ({ onClick }) => {
  return (
    <Tooltip title="Cerrar" arrow>
      <Button onClick={onClick} sx={buttonStyles}>
        <CloseIcon fontSize="large" />
      </Button>
    </Tooltip>
  );
};

export default CloseCircledBtn;
