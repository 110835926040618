import { Box, Container, Grid, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";

// import plantabaja from '../../assets/PLANTABAJA.png'
// import plantaalta from '../../assets/PLANTAALTA.png'

const TableBodyCustom = ({ children }) => {
    return(
        <Box
            sx={{
                backgroundColor:'pink',
                width:'100%',
                display:'flex',
                flexDirection:'column',
            }}
        >
            {children}
        </Box>
    );
}
const TableRowCustom = ({ children }) => {
    return(
        <Box
            sx={{
                backgroundColor:'#ff99ff',
                // width:'100%',
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                p:1,
            }}
        >
            {children}
        </Box>
    );
}
const PlantaArquitectonica = ({ plantas }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    console.log('pla pla',fullScreen);
    // const [plantas, setPlantas] = useState(props.plantas);
    // useEffect(()=>{}, [plantas])
    return(
        <Container sx={{ backgroundColor:'white' }}>
            <Box sx={{ textAlign:'left', marginTop:2, color:'#F5323F' }}>
                 <h1>Planta Arquitectónica</h1>                
            </Box>
            <Box
                sx={{
                    // backgroundColor:'yellow',
                    width:'100%',
                    display:'flex',
                    flexDirection:fullScreen ? 'column' : 'row',
                    alignItems:'flex-start',
                    justifyContent:fullScreen ? 'center' : 'space-around',
                    gap:fullScreen ? 2 : 25,
                    mb:5,
                }}
            >
                {
                    plantas.map(planta => (
                        <Table aria-label="a dense table" sx={{  }}>
                            <TableRow>
                                <TableCell align="center" sx={{ fontWeight:'bold', fontSize:18, color:'#F5323F' }}>{planta.nombre}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Box
                                        sx={{
                                            // backgroundColor:'palegreen',
                                            width:'100%',
                                            height:'450px',
                                            display:'flex',
                                            alignItems:'center',
                                            justifyContent:'center'
                                        }}
                                    >
                                        <Box
                                            component="img"
                                            src={planta.imagen}
                                            sx={{
                                                maxWidth:'70%',
                                                maxHeight:'100%',
                                            }}
                                        />
                                    </Box>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <Table sx={{}}>
                                    <TableRow>
                                        <TableCell align="left" sx={{ fontWeight:'bold', fontSize:16, color:'#F5323F' }}>Habitaciones:</TableCell>
                                        <TableCell align="right" sx={{ fontWeight:'bold', fontSize:16, color:'#F5323F' }}>Cantidad:</TableCell>
                                    </TableRow>
                                    {
                                        planta.habitaciones.map(habitacion => (
                                            <TableRow>
                                                <TableCell align="left" sx={{ fontWeight:'bold', fontSize:14, color:'#707070' }}>{habitacion.nombre}</TableCell>
                                                <TableCell align="right" sx={{ fontWeight:'bold', fontSize:14, color:'#707070' }}>{habitacion.cantidad}</TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </Table>
                            </TableRow>
                        </Table>
                    ))
                }
            </Box>
        </Container>
    );
    // return(
    //     <Container sx={{ backgroundColor:'white' }}>
    //         <Box sx={{ textAlign:'left', marginTop:2, color:'#F5323F' }}>
                
    //              <h1>Planta Arquitectónica</h1>
                
    //         </Box>
            
    //         {/* <Stack> */}
                
    //             <Grid 
    //                 container 
                    
    //                 direction="row"
    //                 justifyContent="center"
    //                 alignItems="center"
    //             >
    //                 <Grid item xs={12} sm={6} md={6}>
    //                     <Grid 
    //                         container
    //                         direction="row"
    //                         justifyContent="center"
    //                         alignItems="flex-start"
    //                         sx={{ 
    //                             // backgroundColor:'yellow',
    //                             minHeight:{ xs:380, sm:400, md:400},
    //                         }}
    //                     >
    //                         <Grid item lg={6}>
    //                             <Stack
    //                                 direction="column"
    //                                 justifyContent="center"
    //                                 alignItems="flex-start"
    //                                 sx={{ mt:5 }}
    //                             >
    //                                 <Typography variant="h5" gutterBottom sx={{ color: '#F5323F', ml: { xs: 1, sm: 2, md: 5 } }} component="div">
    //                                     <b>Planta Baja</b>
    //                                 </Typography>
    //                                 <Typography variant="h6" gutterBottom sx={{ color: '#707070', ml:{ xs: 1, sm: 3, md: 7 } }} component="div">
    //                                     <b>Características</b>
    //                                 </Typography>
    //                                 {
    //                                     plantas && (
    //                                         plantas[0].elementos.map((elemento) => (
    //                                             <Typography variant="li" gutterBottom sx={{ mt: 1, ml:{ xs: 2, sm: 4, md: 9 } }} component="div">
    //                                                 {elemento.nombre}
    //                                             </Typography>            
    //                                         ))
    //                                     )
    //                                 }
    //                             </Stack>
                                
    //                             {/* <Stack>                                    
    //                                 <h2>Planta Baja</h2>
    //                                 <h3>Características</h3>

    //                                 <ul>
    //                                     <li>Coffee</li>
    //                                     <li>Tea</li>
    //                                     <li>Milk</li>
    //                                 </ul> 
    //                             </Stack> */}
    //                         </Grid>
    //                         <Grid item lg={6}>
    //                             {
    //                                 plantas && (
    //                                     <Box
    //                                         component="img"
    //                                         src={plantas[0].imagen}
    //                                         sx={{
    //                                             height:{ xs:330, sm:380, md:380 }
    //                                         }}
    //                                     />
    //                                 )
    //                             }
                                
    //                         </Grid>
    //                     </Grid>
    //                 </Grid>
    //                 <Grid item xs={12} sm={6} md={6} sx={{ display:{ xs:'none', sm:'flex', md:'flex' } }}>
    //                     <Grid 
    //                         container
    //                         direction="row"
    //                         justifyContent="center"
    //                         alignItems="flex-start"
    //                         sx={{ 
    //                             // backgroundColor:'green',
    //                             minHeight:{ xs:380, sm:400, md:400},
    //                         }}
    //                     >
    //                         <Grid item lg={6}>
    //                             <Stack
    //                                 direction="column"
    //                                 justifyContent="center"
    //                                 alignItems="flex-start"
    //                                 sx={{ marginTop:5 }}
    //                             >
    //                                 <Typography variant="h5" gutterBottom sx={{ color: '#F5323F',  ml: { xs: 0, sm: 2, md: 5 }}} component="div">
    //                                     <b>Planta Alta</b>
    //                                 </Typography>
    //                                 <Typography variant="h6" gutterBottom sx={{ color: '#707070', ml:{ xs: 1, sm: 3, md: 7 } }} component="div">
    //                                     <b>Características</b>
    //                                 </Typography>
    //                                 {
    //                                     plantas && (
    //                                         plantas[1].elementos.map((elemento) => (
    //                                             <Typography variant="li" gutterBottom sx={{ mt: 1, ml:{ xs: 2, sm: 4, md: 9 } }} component="div">
    //                                                 {elemento.nombre}
    //                                             </Typography>            
    //                                         ))
    //                                     )
    //                                 }
    //                             </Stack>
    //                         </Grid>
    //                         <Grid item lg={6}>
    //                             {
    //                                 plantas && (
    //                                     <Box
    //                                         component="img"
    //                                         src={plantas[1].imagen}
    //                                         sx={{
    //                                             height:{ xs:340, sm:380, md:380 }
    //                                         }}
    //                                     />
    //                                 )
    //                             }
    //                         </Grid>
    //                     </Grid>
    //                 </Grid>
    //                 <Grid item xs={12} sm={6} md={6} sx={{ display:{ xs:'flex', sm:'none', md:'none' } }}>
    //                     <Grid 
    //                         container
    //                         direction="row-reverse"
    //                         justifyContent="flex-end"
    //                         alignItems="flex-start"
    //                         sx={{ 
    //                             // backgroundColor:'greenyellow',
    //                             minHeight:{ xs:380, sm:400, md:400},
    //                         }}
    //                     >
    //                         <Grid item lg={6}>
    //                             <Stack
    //                                 direction="column"
    //                                 justifyContent="center"
    //                                 alignItems="flex-start"
    //                                 sx={{ marginTop:5 }}
    //                             >
    //                                 <Typography variant="h5" gutterBottom sx={{ color: '#F5323F', ml: { xs: 2, sm: 5, md: 5 } }} component="div">
    //                                     <b>Planta Alta</b>
    //                                 </Typography>
    //                                 <Typography variant="h6" gutterBottom sx={{ color: '#707070', ml:{ xs: 3, sm: 7, md: 7 } }} component="div">
    //                                     <b>Características</b>
    //                                 </Typography>
    //                                 {
    //                                     plantas && (
    //                                         plantas[1].elementos.map((elemento) => (
    //                                             <Typography variant="li" gutterBottom sx={{ mt: 1, ml:{ xs: 4, sm: 9, md: 9 } }} component="div">
    //                                                 {elemento.nombre}
    //                                             </Typography>            
    //                                         ))
    //                                     )
    //                                 }
    //                             </Stack>
    //                         </Grid>
    //                         <Grid item lg={6}>
    //                             {
    //                                 plantas && (
    //                                     <Box
    //                                         component="img"
    //                                         src={plantas[1].imagen}
    //                                         sx={{
    //                                             height:{ xs:330, sm:380, md:380 }
    //                                         }}
    //                                     />
    //                                 )
    //                             }
    //                         </Grid>
    //                     </Grid>
    //                 </Grid>
    //             </Grid>
    //         {/* </Stack> */}
    //     </Container>
    // );
}

export { PlantaArquitectonica }