import * as React from "react";

const AltavistaIcon = ({ width = 32, height = 32, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="currentColor"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#a)">
      <path d="M3.41 4.791c.912 0 1.647-.783 1.647-1.742 0-.96-.74-1.743-1.647-1.743-.908 0-1.648.783-1.648 1.743 0 .96.74 1.742 1.648 1.742Zm0-2.89c.597 0 1.079.516 1.079 1.142 0 .625-.488 1.141-1.08 1.141-.59 0-1.079-.516-1.079-1.141 0-.626.488-1.142 1.08-1.142Z" />
      <path d="M.656 0v23.994h22.691V0H.657Zm15.4.468v15.777L8.785.468h7.27Zm-9.86 0h2.068l7.516 16.305-8.377-3.31-1.2-.485V.468h-.006Zm-5.098 0h1.017a2.81 2.81 0 0 0-1.017.99v-.99Zm0 2.502C1.138 1.652 2.161.595 3.412.595c1.252 0 2.314 1.1 2.314 2.448 0 1.348-1.04 2.447-2.314 2.447S1.14 4.433 1.098 3.115v-.151.006Zm4.496 5.496H1.23c.132-1.148 1.068-2.04 2.182-2.04 1.114 0 2.05.898 2.182 2.04ZM1.098 4.634c.27.437.643.79 1.086 1.026H1.098V4.634Zm0 1.494H2.5a2.725 2.725 0 0 0-1.4 1.275V6.128Zm0 3.17 4.421 4.676H1.1V9.298Zm0 5.15h2.119c-.069 1.001-1.016 2.647-2.119 3.734v-3.735Zm2.349.965c.27.76.77 1.621 1.378 2.374a4.574 4.574 0 0 0-1.378-.206c-.471 0-.936.067-1.378.206.603-.753 1.102-1.615 1.378-2.374Zm-2.349 3.4c.569-.46 1.327-.71 2.108-.752-.19 2.15-.282 2.356-2.108 2.55v-1.797Zm0 4.713v-2.374c1.775.188 1.912.389 2.09 2.374h-2.09Zm1.172-2.641c.66-.195.976-.529 1.16-1.227.183.698.499 1.032 1.16 1.227-.661.194-.977.528-1.16 1.226-.184-.698-.5-1.032-1.16-1.226Zm3.48 2.641H3.67c.179-1.98.316-2.186 2.08-2.374v2.374Zm0-2.915c-1.815-.194-1.913-.407-2.097-2.55.77.036 1.528.273 2.096.716v1.834Zm0-2.471c-1.086-1.087-2.005-2.709-2.074-3.693h2.079v3.693h-.006Zm0-4.585L1.384 8.939h.753l3.611 3.82v.796Zm0-1.476-2.97-3.14h.954l2.015 2.132v1.008Zm0-1.676-1.39-1.47h1.39v1.47Zm.005-2.946a2.748 2.748 0 0 0-1.43-1.33h1.43v1.33Zm0-1.797H4.64c.46-.243.844-.613 1.114-1.069V5.66Zm0-4.154A2.913 2.913 0 0 0 4.71.474h1.045v1.032Zm.442 12.018 1.033.42 8.653 3.418-3.812 3.753-1.797-1.749-4.083 4.015v-9.857h.006Zm1.12 10.002h-.54l3.497-3.443 1.43 1.385-.277.267-1.16-1.124-2.956 2.91.006.005Zm2.296 0H8.046l2.228-2.192.786.765-1.447 1.427Zm4.95 0h-2.791l4.49-4.42 4.553 4.42h-6.253Zm6.993 0-5.294-5.137-5.22 5.137h-.78l6.006-5.824 5.994 5.824h-.706Zm1.343-.09-6.275-6.092 6.275-2.478v8.57Zm0-9.092-6.097 2.411 6.097-13.239v10.828Zm0-11.963-6.384 13.858V.468h6.384V2.38Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default AltavistaIcon;
