import { Box } from "@mui/material";

/**
 * Estilos para la barra inferior fija.
 *
 * La barra permanece fija en la parte inferior de la pantalla y proporciona una sombra sutil
 * para diferenciarla del contenido. Es útil para mostrar acciones o información relevante
 * sin obstruir la vista principal.
 */
const bottomStickyBarStyles = {
  position: "sticky", // Se mantiene visible en la parte inferior al hacer scroll.
  bottom: 0, // Se ancla en la parte inferior de la pantalla.
  zIndex: 1, // Asegura que se mantenga por encima de otros elementos.
  minHeight: 64, // Altura mínima para mantener consistencia visual.
  p: 2, // Espaciado interno.
  boxShadow: "0px -2px 6px rgba(0,0,0,0.1)", // Sombra superior para resaltar la barra.
  bgcolor: "white", // Fondo blanco para contraste con el contenido.
  display: "flex", // Diseño flexible para distribuir elementos internos.
  alignItems: "center", // Alinea los elementos verticalmente al centro.
  justifyContent: "center", // Centra los elementos horizontalmente.
};

/**
 * Componente de barra inferior fija (BottomStickyBar).
 *
 * Este componente proporciona una barra fija en la parte inferior de la pantalla
 * donde se pueden colocar botones, textos o cualquier otro contenido necesario.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {React.ReactNode} props.children - Contenido a renderizar dentro de la barra.
 * @returns {JSX.Element} Componente de barra fija en la parte inferior de la pantalla.
 *
 * @example
 * <BottomStickyBar>
 *   <Button variant="contained">Guardar Cambios</Button>
 * </BottomStickyBar>
 */
const BottomStickyBar = ({ children }) => {
  return <Box sx={bottomStickyBarStyles}>{children}</Box>;
};

export default BottomStickyBar;
