import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";

import { PecsaCalendarIcon } from "../../assets/icons";
import { FraccionamientosDrawerList } from "../fraccionamientos";
import DrawerContent from "../Drawers/drawerComponents/DrawerContent";
import useAppointment from "../../hooks/useAppointment";

const AppointmentFloatingTab = ({ fraccionamientos, isMobile }) => {
  const [open, setOpen] = useState(false);
  const { handleAppointment } = useAppointment();

  // Estado para manejar la animación inicial
  const [initialAnimation, setInitialAnimation] = useState(true);

  useEffect(() => {
    // Después de 2 segundos, mueve el botón hacia la izquierda
    const timer = setTimeout(() => {
      setInitialAnimation(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <AnimatePresence>
      {open ? (
        <motion.div
          initial={{ x: "-100%", opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: "-100%", opacity: 0 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
          style={{
            position: "fixed",
            top: "200px",
            left: "8px",
            width: "350px",
            zIndex: 1000,
          }}
        >
          <Box
            sx={{
              p: 2,
              py: 3,
              bgcolor: "white",
              borderRadius: "8px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              position: "relative",
              maxHeight: "70vh",
              overflowY: "auto",
            }}
          >
            <DrawerContent
              title="Elige tu fraccionamiento"
              isMobile={isMobile}
              onClose={() => setOpen(false)}
            >
              <FraccionamientosDrawerList
                fraccionamientos={fraccionamientos}
                onSelect={handleAppointment}
                open={open}
              />
            </DrawerContent>
          </Box>
        </motion.div>
      ) : (
        <motion.button
          initial={{ left: "0px" }}
          animate={{ left: initialAnimation ? "0px" : "-121px" }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
          onClick={() => setOpen(true)}
          aria-label="Agendar una cita"
          role="button"
          tabIndex={0}
          style={{
            position: "fixed",
            top: "200px",
            left: "-121px",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            padding: "12px",
            color: "white",
            fontSize: "1.6rem",
            backgroundColor: "hsl(356, 91%, 58%)",
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            cursor: "pointer",
            border: "none",
            width: "auto",
            overflow: "hidden",
            transition: "left 0.3s ease-in-out",
          }}
          whileHover={{
            left: "0px",
            backgroundColor: "hsl(356, 91%, 48%)",
            transition: { duration: 0.002, ease: "easeOut" },
          }}
          whileFocus={{
            left: "0px",
            outline: "3px solid white",
            outlineOffset: "2px",
          }}
        >
          <motion.div
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            transition={{ duration: 0.2 }}
            style={{ whiteSpace: "nowrap" }}
          >
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontWeight: 600,
                whiteSpace: "nowrap",
              }}
            >
              Agendar Cita
            </Typography>
          </motion.div>
          <PecsaCalendarIcon width={60} height={60} aria-hidden="true" />
        </motion.button>
      )}
    </AnimatePresence>
  );
};

export default AppointmentFloatingTab;
